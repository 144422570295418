<template>
  <div>
    <!-- picBEC70F72-FF95-4EF1-8F06-E218D7E2132B.jpg -->
    <!-- the image used here is the thumbnail! -->
    <img class="leftimage rounded img-small" src="@/assets/news-network/small/minifig-secrets-revealed.jpg">

    <p>
      The citizens of LEGO Universe---those little LEGO people that we call minifigs---are mighty important to LEGO Universe. In fact, the whole LU game is designed from the minifigs' point of view.
    </p>
    <p>
      In honor of the special role that minifigs play in LU, we spoke with our resident minifig expert, Micki about what those awesome little LEGO figures will be able to do in the game, as well as other cool stuff. Check it out!
    </p>
    <p>
      <br><b>Question: What is the coolest part of working on the LEGO Universe team?</b><br><i>Micki Fjeldgaard, LEGO 3D Technical Designer</i>: The great creative people that I work with, lots of fun and humor
    </p>
    <p>
      <b>Question: What is the most exciting about the LEGO Universe game ?</b><br><i>Micki</i>: All the possibilities, all the stuff you can do with the minifig and super cool looking graphics.
    </p>
    <p>
      <b>Question: Can you give a sneak preview of what the minifigs will be able to do in the game?</b><br><i>Micki</i>: What if I told you that....you can jump, run, fight, build, buy, sell, chat, team up, solve puzzles, go on quests and much, much more!
    </p>
    <p>
      <b>Question: Can I design my own minifig and use it in the game? </b><br><i>Micki</i>: You’ll be able to customize the mini figure in lots of new and exciting ways.
    </p>
    <p>
      <b>Question: Can I get my current LEGO Club minifig in the game? </b><br><i>Micki</i>: You'll be able to transfer your avatar from LEGO Club into the game... and be ready for action!
    </p>
    <p>
      <b>Question: Are the minifigs going to be 3D? </b><br><i>Micki</i>: The minifigs will be in 3D, and will be looking pretty cool!
    </p>
    <p>
      <b>Question: Will I be able to have some cool accessories for my minifig?</b><br><i>Micki</i>: Players will earn accessories as they play to continually building their collection. Then will be able to  add and change accessories as they play in the many different LEGO Universe Worlds.
    </p>
    <!-- picB436B46B-C2A2-4C9E-8E7A-277D24DBFA97.jpg -->
    <img class="rounded rightimage h-62" src="">

    <p>
      <b>Question: What tools and software do you use to create the minifigs?</b><br><i>Micki</i>: The motion of the minifigs, the movement in the game is created in Maya, a 3D program, but before that we look towards the physical minifigs to better understand how they would move, what it can do and how the minifigs look in the real world.
    </p>
    <p>
      <b>Question: What’s your workday like?</b><br><i>Micki</i>: It's very long, with a lot of meetings and different people that I have to talk with, but I also get to play the LEGO Universe game. LOL!
    </p>
    <p>
      <b>Question: What will your own minifig look like? </b><br><i>Micki</i>: He will most likely be very colorful with some spiky hair and sunglasses.
    </p>
    <p>
      <b>Question:  Are your colleagues nice? </b><br><i>Micki</i>: All the people that work at LEGO are very friendly and understanding, even my boss!
    </p>
    <p>
      <b>Question: What is your favorite LEGO set? </b><br><i>Micki</i>: I think that has to be the new millennium falcon (10179) it's a bit expensive, but I saved up for it!
    </p>
    <p>
      <b>Question: Tell us about you!</b><br><i>Micki</i>: I love to go to restaurants to eat dinner, play video games, read books and learn new stuff about computers!
    </p>
    <p>
      <b>Question: What’s your motto, or the thing you say to yourself every morning? </b><br><i>Micki</i>: Let's get it done! I love to work on the project, but I also want it get done so that everybody can play it. It's so much fun!
    </p>
    <div style="clear:both;"></div>
  </div>
</template>
